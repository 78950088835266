import HomeIcon from 'assets/icons/menu/home.svg';
import UserIcon from 'assets/icons/menu/users-svgrepo-com.svg';
import ExperiencerIcon from 'assets/icons/menu/person-hiking-solid.svg';
import ExperiencesIcon from 'assets/icons/menu/backpack.svg'; //activity-adventure-backpack-svgrepo-com.svg'//experience-svgrepo-com.svg';
import BundleIcon from 'assets/icons/menu/gift-svgrepo-com.svg';
import ContractIcon from 'assets/icons/menu/handshake-svgrepo-com.svg';
import HostIcon from 'assets/icons/menu/building-user-solid.svg';
import BookingIcon from 'assets/icons/menu/bookings.svg';
import PaymentIcon from 'assets/icons/menu/cash-register-solid.svg';
import ResourceIcon from 'assets/icons/menu/resource.svg';
import PercentageIcon from 'assets/icons/percentage.svg';
import MarkUpsIcon from 'assets/icons/menu/markups.svg';

// import ArrowForwardIos from '@material-ui/icons';

import { ROUTES } from 'core/routing/routes';

export const triperMenu = [
    {
        name: 'layout.menu.home',
        link: ROUTES.HOME,
        icon: HomeIcon,
    },
    // {
    //   name: 'layout.menu.profile',
    //   link: ROUTES.PROFILE,
    //   // icon: ProfileIcon,
    // },
    // {
    //   name: 'layout.menu.my_trips',
    //   link: ROUTES.TRIP.HOME,
    //   // icon: MyTripsIcon,
    // },
    // {
    //   name: 'layout.menu.calendar',
    //   link: ROUTES.ITINERARY,
    //   // icon: CalendarIcon,
    // },
    // {
    //   name: 'layout.menu.budget',
    //   link: ROUTES.BUDGET,
    //   // icon: BudgetIcon,
    // },
];

export const experiencerMenu = [
    {
        name: 'layout.menu.home',
        link: '/home',
        icon: HomeIcon,
    },
    {
        name: 'layout.menu.user',
        link: '/user',
        icon: UserIcon,
    },
    {
        name: 'layout.menu.experiencer',
        link: '/experiencer',
        icon: ExperiencerIcon,
    },
    {
        name: 'layout.menu.resource',
        link: '/resource',
        icon: ResourceIcon,
    },
    {
        name: 'layout.menu.experiences',
        link: '/experience',
        icon: ExperiencesIcon,
    },
    {
        name: 'layout.menu.bundle',
        link: '/bundle',
        icon: BundleIcon,
    },
    {
        name: 'layout.menu.discounts',
        link: '/discount',
        icon: PercentageIcon,
    },
    {
        name: 'layout.menu.markups',
        link: '/markup',
        icon: MarkUpsIcon,
    },
    {
        name: 'layout.menu.host',
        link: '/host',
        icon: HostIcon,
    },
    {
        name: 'layout.menu.contracts',
        link: '/contract',
        icon: ContractIcon,
    },
    {
        name: 'layout.menu.bookings',
        link: '/booking',
        icon: BookingIcon,
    },
    {
        name: 'layout.menu.payments',
        link: '/payment',
        icon: PaymentIcon,
    },
];
