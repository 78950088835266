import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import {
    Typography,
    Grid,
    useMediaQuery,
    Divider,
    Button,
} from '@material-ui/core';

import EditIcon from 'assets/icons/edit-white.svg';

import Layout from 'components/Layout';

import Title from 'core/libs/core-ui/components/Title';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';

import { useTranslation } from 'core/contexts/localization';
import { useMarkup } from 'core/contexts/markups';

import { useStyles } from './styles';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';

const MarkupPage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const { t: translate } = useTranslation();

    const { getMarkup, loading, addMarkup, updateMarkup, markup, setMarkup } =
        useMarkup();

    const [markupChanged, setMarkupChanged] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [markupInfo, setMarkupInfo] = useState({});
    const [markupData, setMarkupData] = useState({});

    const locationParam = document.location.href.split('/');
    const markupIndex = locationParam[locationParam.length - 1]
        ? parseInt(locationParam[locationParam.length - 1])
        : false;

    const onSubmit = async (values) => {
        const { status, markupRate } = values;
        if (!values) return;
        try {
            if (markupIndex == 0) {
                const valuesClon = { ...values };
                if (!markupChanged || !status || !markupRate) {
                    toast.error(
                        'Por favor Ingresar al menos un estado y agregado',
                        { position: 'top-right', theme: 'colored' }
                    );
                    throw new Error('');
                } else {
                    const newMarkup = await addMarkup(valuesClon);
                    getMarkup(newMarkup.id);
                    history.push(`/markup/${newMarkup.id}`);
                    setCanEdit(false);
                }
            } else {
                const valuesClon = { ...values };
                if (!markupChanged || !status || !markupRate) {
                    toast.error(
                        'Por favor Ingresar al menos un estado y agregado',
                        { position: 'top-right', theme: 'colored' }
                    );
                    throw new Error('');
                } else {
                    await updateMarkup(valuesClon);
                    setCanEdit(false);
                    getMarkup(markupIndex);
                }
            }
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    useEffect(() => {
        setMarkup({});
        if (markupIndex == 0) {
            setCanEdit(true);
        } else {
            getMarkup(markupIndex);
        }
    }, []);

    useEffect(() => {
        setMarkupData(markup);
    }, [markup]);

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Markup'} />
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    <Grid>
                        <div className={classes.formContainer}>
                            <Title value={markup?.name} size={'l'} />
                            <Form
                                onSubmit={onSubmit}
                                initialValues={{
                                    ...markupData,
                                }}
                                render={({ handleSubmit, form }) => (
                                    <form id="form" onSubmit={handleSubmit}>
                                        <div>
                                            {markupIndex !== 0 ? (
                                                <>
                                                    <div
                                                        className={
                                                            classes.divEditButton
                                                        }>
                                                        <Typography
                                                            style={{
                                                                fontWeight:
                                                                    'bold',
                                                                marginBottom:
                                                                    '10px',
                                                            }}
                                                            variant="h5"
                                                            className={
                                                                classes.text
                                                            }>
                                                            {'Edit Markup Data'}
                                                        </Typography>
                                                        <Button
                                                            type="button"
                                                            onClick={() => {
                                                                setCanEdit(
                                                                    true
                                                                );
                                                            }}
                                                            style={{
                                                                height: 40,
                                                                margin: 'inherit',
                                                            }}
                                                            className={
                                                                classes.editButton
                                                            }>
                                                            <img
                                                                src={EditIcon}
                                                                width={20}
                                                            />
                                                            {isSmall ? null : (
                                                                <>&nbsp;</>
                                                            )}
                                                            {isSmall
                                                                ? null
                                                                : `Edit`}
                                                        </Button>
                                                    </div>

                                                    <Divider />
                                                </>
                                            ) : (
                                                <Typography
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '10px',
                                                    }}
                                                    variant="h5"
                                                    className={classes.text}>
                                                    Crea el agregado:
                                                </Typography>
                                            )}

                                            <div style={{ marginTop: '20px' }}>
                                                <Grid
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Field
                                                            id="id"
                                                            name="id"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={'Id'}
                                                            label={'Id'}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                markupInfo.start =
                                                                    value;
                                                                setMarkupInfo(
                                                                    markupInfo
                                                                );
                                                                setMarkupChanged(
                                                                    true
                                                                );
                                                            }}
                                                            name="start"
                                                            id="start"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={'Start'}
                                                            label={'Start'}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                markupInfo.additional =
                                                                    value;
                                                                setMarkupInfo(
                                                                    markupInfo
                                                                );
                                                                setMarkupChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="additional"
                                                            name="additional"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={
                                                                'additional'
                                                            }
                                                            label={'additional'}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                markupInfo.end =
                                                                    value;
                                                                setMarkupInfo(
                                                                    markupInfo
                                                                );
                                                                setMarkupChanged(
                                                                    true
                                                                );
                                                            }}
                                                            name="end"
                                                            id="end"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={'End'}
                                                            label={'End'}
                                                            disabled={!canEdit}
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                markupInfo.createdBy =
                                                                    value;
                                                                setMarkupInfo(
                                                                    markupInfo
                                                                );
                                                                setMarkupChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="createdBy"
                                                            name="createdBy"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={
                                                                'createdBy'
                                                            }
                                                            label={'createdBy'}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                markupInfo.createdOn =
                                                                    value;
                                                                setMarkupInfo(
                                                                    markupInfo
                                                                );
                                                                setMarkupChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="createdOn"
                                                            name="createdOn"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={
                                                                'createdOn'
                                                            }
                                                            label={'createdOn'}
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid item xs={12} sm={6}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                markupInfo.updatedBy =
                                                                    value;
                                                                setMarkupInfo(
                                                                    markupInfo
                                                                );
                                                                setMarkupChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="updatedBy"
                                                            name="updatedBy"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={
                                                                'updatedBy'
                                                            }
                                                            label={'updatedBy'}
                                                            disabled
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                markupInfo.updatedOn =
                                                                    value;
                                                                setMarkupInfo(
                                                                    markupInfo
                                                                );
                                                                setMarkupChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="updatedOn"
                                                            name="updatedOn"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={
                                                                'updatedOn'
                                                            }
                                                            label={'updatedOn'}
                                                            disabled
                                                        />
                                                    </Grid>
                                                </Grid>
                                                <Grid
                                                    container
                                                    direction={'row'}
                                                    justifyContent={'center'}
                                                    alignItems={'center'}
                                                    spacing={3}>
                                                    <Grid
                                                        item
                                                        xs={12}
                                                        sm={6}
                                                        container>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                id="status"
                                                                name="status"
                                                                render={({
                                                                    input,
                                                                    meta,
                                                                }) => (
                                                                    <SelectComponent
                                                                        disabled={
                                                                            !canEdit
                                                                        }
                                                                        {...input}
                                                                        values={[
                                                                            {
                                                                                name: '0',
                                                                                id: '0',
                                                                            },
                                                                            {
                                                                                name: '1',
                                                                                id: '1',
                                                                            },
                                                                        ]}
                                                                        label={
                                                                            'Status'
                                                                        }
                                                                        labelId={
                                                                            'status-label'
                                                                        }
                                                                        hasError={
                                                                            meta.touched &&
                                                                            !!meta.error
                                                                        }
                                                                        errorMessage={
                                                                            meta.error
                                                                        }
                                                                        onFieldChange={(
                                                                            event
                                                                        ) => {
                                                                            input.onChange(
                                                                                event
                                                                            );
                                                                            const value =
                                                                                event
                                                                                    .target
                                                                                    .value;
                                                                            markupInfo.status =
                                                                                value;
                                                                            setMarkupInfo(
                                                                                {
                                                                                    ...markupInfo,
                                                                                }
                                                                            );
                                                                            setMarkupChanged(
                                                                                true
                                                                            );
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    markupInfo.markupRate =
                                                                        value;
                                                                    setMarkupInfo(
                                                                        markupInfo
                                                                    );
                                                                    setMarkupChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="markupRate"
                                                                name="markupRate"
                                                                component={
                                                                    TextInput
                                                                }
                                                                type="number"
                                                                hintText={
                                                                    'markupRate'
                                                                }
                                                                label={
                                                                    'markupRate'
                                                                }
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid item xs={12} sm={6}>
                                                        <Field
                                                            onFieldChange={(
                                                                value
                                                            ) => {
                                                                markupInfo.cancelReason =
                                                                    value;
                                                                setMarkupInfo(
                                                                    markupInfo
                                                                );
                                                                setMarkupChanged(
                                                                    true
                                                                );
                                                            }}
                                                            id="cancelReason"
                                                            name="cancelReason"
                                                            component={
                                                                TextInput
                                                            }
                                                            hintText={
                                                                'cancelReason'
                                                            }
                                                            label={
                                                                'cancelReason'
                                                            }
                                                            disabled={
                                                                !canEdit ||
                                                                markupIndex == 0
                                                            }
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </div>

                                        <Grid
                                            item
                                            container
                                            justify="flex-end"
                                            alignContent="flex-end">
                                            <Grid
                                                item
                                                container
                                                justify={'space-between'}
                                                style={{ width: 'auto' }}>
                                                <Button
                                                    className="rounded mr-3 height-40 w-100px"
                                                    disabled={!canEdit}
                                                    type="button"
                                                    onClick={(e) => {
                                                        setCanEdit(false);
                                                        return form.reset(e);
                                                    }}>
                                                    {translate('btn_cancel')}
                                                </Button>

                                                <Button
                                                    onClick={(e) => {
                                                        return handleSubmit(e);
                                                    }}
                                                    className="rounded height-40 w-100px triper-button bg-green-300"
                                                    disabled={!canEdit}
                                                    type="submit">
                                                    {translate('btn_submit')}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                )}
                            />
                        </div>
                    </Grid>
                )}
            </div>
        </Layout>
    );
};

MarkupPage.displayName = 'MarkupPage';

export default MarkupPage;
