import React, { useContext, useState } from 'react';
import { ExperienceService } from 'core/api/services/experience';

const ExperienceContext = React.createContext(null);

export const ExperienceProvider = ({ children }) => {
    const [loading, setLoading] = useState(false);
    const [experiences, setExperiences] = useState([]);
    const [experience, setExperience] = useState([]);
    const [experienceSchedule, setExperienceSchedule] = useState([]);
    const [total, setTotal] = useState([]);

    const getExperiences = async (options) => {
        try {
            setLoading(true);
            const result = await ExperienceService.getExperiences(options);
            if (!result) {
                throw new Error();
            }
            setExperiences(result.experiences);
            setTotal(result.total);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getExperience = async (id) => {
        try {
            setLoading(true);
            const result = await ExperienceService.get(id);
            if (!result) {
                throw new Error();
            }
            setExperience(result);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const updateExperience = async (values) => {
        try {
            setLoading(true);
            const result = await ExperienceService.update(values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const getExperienceSchedule = async (range) => {
        try {
            if(!experience.id) {return;}
            setLoading(true);
            const result = await ExperienceService.getExperienceSchedule({id: experience.id, range});
            if (!result) {
                throw new Error();
            }
            setExperienceSchedule(result);
            return true;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };
    const addExperienceSchedule = async (values) => {
      try {
          setLoading(true);
          const result = await ExperienceService.addExperienceSchedule(values);
          if (!result) {
              throw new Error();
          }
          return result;
      } catch (err) {
          console.error(err);
          return false;
      } finally {
          setLoading(false);
      }
  };
    const updateExperienceSchedule = async (values) => {
        try {
            setLoading(true);
            const result = await ExperienceService.updateExperienceSchedule(values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    const deleteExperienceSchedule = async (values) => {
        try {
            setLoading(true);
            const result = await ExperienceService.deleteExperienceSchedule(values);
            if (!result) {
                throw new Error();
            }
            return result;
        } catch (err) {
            console.error(err);
            return false;
        } finally {
            setLoading(false);
        }
    };

    return (
        <ExperienceContext.Provider
            value={{
                loading,
                experiences,
                total,
                getExperiences,
                experience,
                setExperience,
                getExperience,
                updateExperience,
                experienceSchedule,
                setExperienceSchedule,
                getExperienceSchedule,
                addExperienceSchedule,
                updateExperienceSchedule,
                deleteExperienceSchedule,
            }}>
            {children}
        </ExperienceContext.Provider>
    );
};

ExperienceProvider.displayName = 'ExperienceProvider';

export const useExperience = () => useContext(ExperienceContext);
