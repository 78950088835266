import { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-final-form';
import { toast } from 'react-toastify';
import {
    Grid,
    Typography,
    useMediaQuery,
    Divider,
    Button,
    Card,
    CardContent,
} from '@material-ui/core';

import Layout from 'components/Layout';
import Title from 'core/libs/core-ui/components/Title';
import TextInput from 'core/libs/core-ui/components/Input/Text';
import Checkbox from 'core/libs/core-ui/components/Input/Checkbox';
import CircularIndeterminate from 'core/libs/core-ui/components/Progress';
import EditIcon from 'assets/icons/edit-white.svg';

import { useTranslation } from 'core/contexts/localization';
import { useExperience } from 'core/contexts/experience';

import { useStyles } from './styles';

import CalendarPage from './components/Calendar';
import ExperienceStatus from 'core/libs/status/ExperienceStatus/ExperienceStatus';
import SelectComponent from 'core/libs/core-ui/components/Input/Select';
import PayNow from 'core/libs/types/PayNow/PayNow';

const ExperiencePage = () => {
    const classes = useStyles();
    const history = useHistory();
    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('xs'));
    const { t: translate, i18n } = useTranslation();
    const statusOptions = useMemo(() => ExperienceStatus?.getIds().map((id) => {
        const translated = ExperienceStatus.getTranslatedName(ExperienceStatus.status, id, i18n.language);
        return { name: translated?.[0] ?? "", id }
    }), [ExperienceStatus, i18n.language])
    const payNowOptions = useMemo(() => PayNow?.getIds().map((id) => {
        const translated = PayNow.getTranslatedName(PayNow.types, id,  i18n.language);
        return { name: translated?.[0] ?? "", id}
    }))

    const {
        loading,
        experience,
        getExperience,
        setExperience,
        // addExperience,
        updateExperience,
    } = useExperience();

    const [experienceChanged, setExperienceChanged] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [experienceInfo, setExperienceInfo] = useState({});
    const [experienceData, setExperienceData] = useState({});
    const [requireId, setRequireId] = useState(null);
    const [payByCash, setPayByCash] = useState(false);
    const [payByTerminal, setPayByTerminal] = useState(false);
    const [payByLink, setPayByLink] = useState(false);

    const locationParam = document.location.href.split('/');
    const experienceIndex = parseInt(locationParam[locationParam.length - 1]);




    const onSubmit = async (values) => {
        try {
            if (experienceIndex === 0) {
                // const valuesClone = { ...values };
                // const newExperience = await addExperience(valuesClone);
                // history.push(`/experience/${newExperience.id}`);
                history.push(`/experience`);
            } else {
                const valuesClone = { ...values };
                valuesClone.requireId = requireId;
                valuesClone.payByCash = payByCash;
                valuesClone.payByTerminal = payByTerminal;
                valuesClone.payByLink = payByLink;
                if (experienceChanged) {
                    await updateExperience(valuesClone);
                }
                setCanEdit(false);
                getExperience(experienceIndex);
            }
        } catch (err) {
            toast.error(err, {
                position: 'top-right',
                theme: 'colored',
            });
        }
    };

    useEffect(() => {
        setExperience({});
        // if (experienceIndex === 0) {
        //     // setCanEdit(true);
        //     history.push(`/experience`);
        // } else {
        getExperience(experienceIndex);
        // }
    }, []);

    useEffect(() => {
        setExperienceData(experience);
        setRequireId(experience.requireId || false);
        // setPayByCash(experience.payByCash);
        // setPayByTerminal(experience.payByTerminal);
        // setPayByLink(experience.payByLink);
    }, [experience]);

    return (
        <Layout contentClassName={classes.content}>
            <Title value={'Experience'} />
            <div>
                {loading ? (
                    <CircularIndeterminate />
                ) : (
                    // STARTS EXPERIENCER INFO
                    <div className={classes.formContainer}>
                        <Title value={experience.name} size={'l'} />
                        <Form
                            onSubmit={onSubmit}
                            initialValues={{
                                ...experienceData,
                            }}
                            render={({ handleSubmit, form }) => (
                                <form id="form" onSubmit={handleSubmit}>
                                    <div>
                                        <div
                                            className={
                                                classes.divEditButton
                                            }>
                                            <Typography
                                                style={{
                                                    fontWeight: 'bold',
                                                    marginBottom: '10px',
                                                }}
                                                variant="h5"
                                                className={classes.text}>
                                                {translate(
                                                    'experience.edit_experience_data'
                                                )}
                                            </Typography>
                                            <Button
                                                type="button"
                                                onClick={() => {
                                                    setCanEdit(true);
                                                }}
                                                style={{
                                                    height: 40,
                                                    margin: 'inherit',
                                                }}
                                                className={
                                                    classes.editButton
                                                }>
                                                <img
                                                    src={EditIcon}
                                                    width={20}
                                                />
                                                {isSmall ? null : (
                                                    <>&nbsp;</>
                                                )}
                                                {isSmall
                                                    ? null
                                                    : `${translate(
                                                        'experience.edit'
                                                    )}`}
                                            </Button>
                                        </div>

                                        <Divider />

                                        <div
                                            style={{
                                                marginTop: '20px',
                                                marginBottom: '20px',
                                            }}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={3}>
                                                    {experience.pictureURL ? (
                                                        <>
                                                            <Card
                                                                style={{
                                                                    // minWidth: 275,
                                                                    maxWidth: 375,
                                                                }}>
                                                                <CardContent>
                                                                    <img
                                                                        src={`${process.env.REACT_APP_CDN_URL}${experience.pictureURL}`}
                                                                        style={{
                                                                            width: '100%',
                                                                        }}
                                                                    />
                                                                </CardContent>
                                                            </Card>
                                                            <br />
                                                        </>
                                                    ) : null}
                                                </Grid>
                                                <Grid item xs={12} sm={9}>
                                                    <Grid
                                                        container
                                                        direction={'row'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={
                                                            'center'
                                                        }
                                                        spacing={3}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                id="id"
                                                                name="id"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.id'
                                                                )}
                                                                label={translate(
                                                                    'experience.id'
                                                                )}
                                                                disabled
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                name="experiencerId"
                                                                id="experiencerId"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.experiencerId'
                                                                )}
                                                                label={translate(
                                                                    'experience.experiencerId'
                                                                )}
                                                                disabled={
                                                                    true
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction={'row'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={
                                                            'center'
                                                        }
                                                        spacing={3}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    experienceInfo.name =
                                                                        value;
                                                                    setExperienceInfo(
                                                                        experienceInfo
                                                                    );
                                                                    setExperienceChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="name"
                                                                name="name"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.name'
                                                                )}
                                                                label={translate(
                                                                    'experience.name'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    experienceInfo.description1 =
                                                                        value;
                                                                    setExperienceInfo(
                                                                        experienceInfo
                                                                    );
                                                                    setExperienceChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                name="description1"
                                                                id="description1"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.description1'
                                                                )}
                                                                label={translate(
                                                                    'experience.description1'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction={'row'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={
                                                            'center'
                                                        }
                                                        spacing={3}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    experienceInfo.anticipation =
                                                                        value;
                                                                    setExperienceInfo(
                                                                        experienceInfo
                                                                    );
                                                                    setExperienceChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="anticipation"
                                                                name="anticipation"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.anticipation'
                                                                )}
                                                                label={translate(
                                                                    'experience.anticipation'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    experienceInfo.location =
                                                                        value;
                                                                    setExperienceInfo(
                                                                        experienceInfo
                                                                    );
                                                                    setExperienceChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="location"
                                                                name="location"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.location'
                                                                )}
                                                                label={translate(
                                                                    'experience.location'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction={'row'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={
                                                            'center'
                                                        }
                                                        spacing={3}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    experienceInfo.currency =
                                                                        value;
                                                                    setExperienceInfo(
                                                                        experienceInfo
                                                                    );
                                                                    setExperienceChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="currency"
                                                                name="currency"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.currency'
                                                                )}
                                                                label={translate(
                                                                    'experience.currency'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    experienceInfo.price =
                                                                        value;
                                                                    setExperienceInfo(
                                                                        experienceInfo
                                                                    );
                                                                    setExperienceChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="price"
                                                                name="price"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.price'
                                                                )}
                                                                label={translate(
                                                                    'experience.price'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction={'row'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={
                                                            'center'
                                                        }
                                                        spacing={3}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}
                                                            container
                                                        >
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                            >
                                                                <Field
                                                                    id="payNowType"
                                                                    name="payNowType"
                                                                    render={({ input, meta }) => (
                                                                        <SelectComponent
                                                                            {...input}
                                                                            values={payNowOptions}
                                                                            label="Tipo de Anticipo"
                                                                            labelId="payNowType-label"
                                                                            hasError={meta.touched && !!meta.error}
                                                                            errorMessage={meta.error}
                                                                            onFieldChange={(event) => {
                                                                                input.onChange(event);
                                                                                const value = event.target.value;
                                                                                experienceInfo.payNowType = value;
                                                                                setExperienceInfo({ ...experienceInfo });
                                                                                setExperienceChanged(true);
                                                                            }}
                                                                            disabled={!canEdit}
                                                                        />
                                                                    )}
                                                                    
                                                                />
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                sm={6}
                                                            >
                                                                <Field
                                                                    onFieldChange={(
                                                                        value
                                                                    ) => {
                                                                        experienceInfo.payNow =
                                                                            value;
                                                                        setExperienceInfo(
                                                                            experienceInfo
                                                                        );
                                                                        setExperienceChanged(
                                                                            true
                                                                        );
                                                                    }}
                                                                    id="payNow"
                                                                    name="payNow"
                                                                    component={
                                                                        TextInput
                                                                    }
                                                                    hintText={translate(
                                                                        'experience.payNow'
                                                                    )}
                                                                    label={translate(
                                                                        'experience.payNow'
                                                                    )}
                                                                    disabled={
                                                                        !canEdit
                                                                    }
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    experienceInfo.duration =
                                                                        value;
                                                                    setExperienceInfo(
                                                                        experienceInfo
                                                                    );
                                                                    setExperienceChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="duration"
                                                                name="duration"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.duration'
                                                                )}
                                                                label={translate(
                                                                    'experience.duration'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction={'row'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={
                                                            'center'
                                                        }
                                                        spacing={3}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                style={{
                                                                    paddingTop:
                                                                        '0',
                                                                }}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                                checked={
                                                                    requireId
                                                                }
                                                                onChange={(
                                                                    value
                                                                ) => {
                                                                    setRequireId(
                                                                        value
                                                                    );
                                                                    setExperienceChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                name="requireId"
                                                                component={
                                                                    Checkbox
                                                                }
                                                                labelComponent={translate(
                                                                    'experience.requireId'
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                id="createdOn"
                                                                name="createdOn"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.createdOn'
                                                                )}
                                                                label={translate(
                                                                    'experience.createdOn'
                                                                )}
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        direction={'row'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={
                                                            'center'
                                                        }
                                                        spacing={3}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                id="status"
                                                                name="status"
                                                                render={({ input, meta }) => (
                                                                    <SelectComponent
                                                                        {...input}
                                                                        values={statusOptions}
                                                                        label="Estado"
                                                                        labelId="status-label"
                                                                        hasError={meta.touched && !!meta.error}
                                                                        errorMessage={meta.error}
                                                                        onFieldChange={(event) => {
                                                                            input.onChange(event);
                                                                            const value = event.target.value;
                                                                            experienceInfo.status = value;
                                                                            setExperienceInfo({ ...experienceInfo });
                                                                            setExperienceChanged(true);
                                                                        }}
                                                                        disabled={!canEdit}
                                                                    />
                                                                )}
                                                            />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={6}>
                                                            <Field
                                                                id="publishedOn"
                                                                name="publishedOn"
                                                                component={
                                                                    TextInput
                                                                }
                                                                hintText={translate(
                                                                    'experience.publishedOn'
                                                                )}
                                                                label={translate(
                                                                    'experience.publishedOn'
                                                                )}
                                                                disabled
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                    <Grid
                                                        container
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={
                                                            'center'
                                                        }
                                                        spacing={3}>

                                                        <Grid
                                                            item
                                                            xs={12}
                                                        >
                                                            <Field
                                                                style={{
                                                                    // marginBottom:
                                                                    //     '2em',
                                                                    width: '99%',
                                                                }}
                                                                onFieldChange={(
                                                                    value
                                                                ) => {
                                                                    experienceInfo.description2 =
                                                                        value;
                                                                    setExperienceInfo(
                                                                        experienceInfo
                                                                    );
                                                                    setExperienceChanged(
                                                                        true
                                                                    );
                                                                }}
                                                                id="description2"
                                                                name="description2"
                                                                component={
                                                                    'textarea'
                                                                }
                                                                label={translate(
                                                                    'experience.description2'
                                                                )}
                                                                disabled={
                                                                    !canEdit
                                                                }
                                                                multiline
                                                                rows={8}
                                                            // initialCharacters={contract?.description?.length || 500}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </div>
                                    </div>

                                    <Grid
                                        item
                                        container
                                        justify="flex-end"
                                        alignContent="flex-end">
                                        <Grid
                                            item
                                            container
                                            justify={'space-between'}
                                            style={{ width: 'auto' }}>
                                            <Button
                                                className="rounded mr-3 height-40 w-100px"
                                                disabled={!canEdit}
                                                type="button"
                                                onClick={(e) => {
                                                    setCanEdit(false);
                                                    return form.reset(e);
                                                }}>
                                                {translate('btn_cancel')}
                                            </Button>

                                            <Button
                                                onClick={(e) => {
                                                    setCanEdit(false);
                                                    return handleSubmit(e);
                                                }}
                                                className="rounded height-40 w-100px triper-button bg-green-300"
                                                disabled={!canEdit}
                                                type="submit">
                                                {translate('btn_submit')}
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </form>
                            )}
                        />
                    </div>
                    // ENDS EXPERIENCER INFO
                )}

                <Divider />
                <CalendarPage />
            </div>
        </Layout>
    );
};

ExperiencePage.displayName = 'ExperiencePage';

export default ExperiencePage;
