import { useState, useRef, useMemo, useEffect } from 'react';
import { useMediaQuery } from '@material-ui/core';

export const useCalendarPage = () => {
    const calendarRef = useRef(null);

    const isSmall = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    
    const initialEventValues = {
        id: -1,
        capacity: null,
        price: null,
        currency: 'COP',
        repeat: false,
        rRule: '',
        unlimited: false,
        privateSlot: false,
        timezone: null,
    };

    const [date, setDate] = useState(new Date());
    const [start, setStart] = useState('');
    const [end, setEnd] = useState('');
    const [range, setRange] = useState({}); // No se usa!
    const [clickedEventValues, setClickedEventValues] = useState(initialEventValues);
    const [clickedSchedule, setClickedSchedule] = useState(false);
    const [position, setCoordinates] = useState({ x: 0, y: 0 });
    const [open, setOpen] = useState(false);
    const [tz, setTz] = useState(null);


    useEffect(() => {
        const calendarApi = calendarRef.current?.getApi();
        calendarApi?.gotoDate(date);
    }, [date]);

    const onSelect = ({ startStr, endStr, jsEvent: { x, y } = {x:50,y:50}, start = new Date(), end = new Date(), ...props }) => {

        // TODO Here we should use the start and end Date object and get the local vs UTC time as we need to send UTC real times.
        // If a user select datetime and timezone, the datetime selected need to be converted to UTC by add the offset (time + timezone_offset)

        setStart(start);
        setEnd(end);
        setCoordinates({ x, y });
        setRange({ from: start, to: end });
        setOpen(true);
    };

    const onDismiss = () => {
        setOpen(false);
        setClickedEventValues(initialEventValues);
        setClickedSchedule(false);
    };

    const onSave = async () => {
        setOpen(false);
    };

    const onEventClick = (event) => {
        setClickedSchedule(true);
        setClickedEventValues({
            price: event.event.extendedProps.price,
            capacity: event.event.extendedProps.capacity,
            currency: event.event.extendedProps.currency,
            unlimited: event.event.extendedProps.unlimited,
            id: parseInt(event.event.id),
            repeat: event.event.extendedProps.repeat,
            rRule: event.event.extendedProps.rRule,
            privateSlot: event.event.extendedProps.privateSlot,
            timezone: event.event.extendedProps.timezone,
            experienceScheduleId: event.event.extendedProps.experienceScheduleId,
            experienceDetailId: event.event.extendedProps.experienceDetailId,
            scheduleExceptionsId: event.event.extendedProps.scheduleExceptionsId,
            startUTC: event.event.extendedProps.startUTC,
            endUTC: event.event.extendedProps.endUTC,
        });
        onSelect({
            startStr: event.event.startStr,
            endStr: event.event.endStr,
            jsEvent: event.jsEvent,
            start: event.event.start,
            end: event.event.end,
        });
    };

    return {  // REVISAR QUE NECESITO DE ESTO!
        isSmall,
        onEventClick,
        date: {
            value: date,
            onChange: setDate,
        },
        calendar: {
            ref: calendarRef,
            // events,
            onSelect,
            onDismiss,
            onSave,
            range: range,
            start: start,
            end: end,
            tz, 
            setStart,
            setEnd,
            setTz,
        },
        popOver: {
            show: open,
            position,
            start: start,
            end: end,
            setStart,
            setEnd,
            clickedEventValues,
            clickedSchedule,
        },
        filters: {
        },
    };
};
