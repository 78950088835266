import { useEffect } from 'react';
// import WebFont from 'webfontloader';
import DateFnsUtils from '@date-io/date-fns';
import { ThemeProvider } from '@material-ui/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import theme from './core/theme';
import Router from './core/routing';

import { AuthProvider } from './core/contexts/auth';
import { ExperiencerProvider } from './core/contexts/experiencer';
import { UserProvider } from './core/contexts/user';
import { HostProvider } from './core/contexts/host';
import { ExperienceProvider } from './core/contexts/experience';
import { ContractProvider } from './core/contexts/contract';
import { BookingProvider } from './core/contexts/booking';
import { PaymentProvider } from './core/contexts/payment';
import { BundleProvider } from './core/contexts/bundle';
import { ResourceProvider } from './core/contexts/resource';
import { DiscountProvider } from './core/contexts/discounts';
// import { ModusesProvider } from './core/contexts/modus';
// import { LocationsProvider } from './core/contexts/location';
// import { CoTripersProvider } from './core/contexts/coTriper';
// import { ExperiencesProvider } from './core/contexts/experience';
// import { FiltersProvider } from './core/contexts/filter';
import { LocalizationProvider } from './core/contexts/localization';
// import { SortsProvider } from './core/contexts/sort';
// import { HostelProvider } from './core/contexts/hostel';
// import { PaggingProvider } from './core/contexts/pagging';
import getDatepickerLocale from './core/locales/getDatepickerLocale';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MarkupProvider } from 'core/contexts/markups';

const App = () => {
    useEffect(() => {
        // WebFont.load({
        //   google: {
        //     families: ['Mina', 'Mina:bold'],
        //   },
        // });
    }, []);

    return (
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getDatepickerLocale()}>
                <LocalizationProvider>
                    <AuthProvider>
                        <UserProvider>
                            <ExperiencerProvider>
                                <ResourceProvider>
                                    <ExperienceProvider>
                                        <BundleProvider>
                                            <HostProvider>
                                                <ContractProvider>
                                                    <BookingProvider>
                                                        <PaymentProvider>
                                                            <DiscountProvider>
                                                                <MarkupProvider>
                                                                    <Router />
                                                                </MarkupProvider>
                                                            </DiscountProvider>
                                                        </PaymentProvider>
                                                    </BookingProvider>
                                                </ContractProvider>
                                            </HostProvider>
                                        </BundleProvider>
                                    </ExperienceProvider>
                                </ResourceProvider>
                            </ExperiencerProvider>
                        </UserProvider>
                    </AuthProvider>
                </LocalizationProvider>
                <ToastContainer />
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    );
};

App.displayName = 'App';

export default App;
